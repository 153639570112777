class ReviewCard extends HTMLElement {
  constructor() {
    super();
    this.name = this.getAttribute('name') || '';
    this.date = this.getAttribute('date') || '';
    this.review = this.getAttribute('review') || '';
  }

  connectedCallback() {
    this.render();
  }

  get value() {
    return this.getAttribute('value');
  }

  set value(value) {
    this.setAttribute('value', value);
    this.render();
  }

  render() {
    this.innerHTML = `
      <style>
        .review-list {
          padding: 1rem 2rem;
          margin-top: 1rem;
          background: #fff;
        }

        .review-list p {
          margin: 1rem 0 0;
          border-top: 1px solid #e2e2e2;
          padding-top: 1rem;
        }

        .review-list > div > div {
          font-family: 'Montserrat', sans-serif;
          align-self: center;
        }

        .review-list > div > div:first-child {
          margin-right: 0.65rem;
        }

        .review-list > div > div span {
          display: block;
          font-size: 0.8rem;
          color: #484848;
        }

        .review-list > div > div i {
          background: #9a8075;
          width: 44px;
          height: 44px;
          color: #eeeeee;
          font-size: 1.6rem;
          text-align: center;
          padding-top: 8px;
          border-radius: 100%;
        }
        @media all and (max-width: 514px){
          .review-list {
            padding: 1rem;
          }
        }
      </style>

      <div class="review-list">
        <div class="flex">
          <div>
            <i class="fa fa-user"></i>
          </div>
          <div>
            ${this.name}
            <span>${this.date}</span>
          </div>
        </div>
        <p>${this.review}</p>
      </div>
    `;
  }
}

if(!customElements.get('review-card')){
  customElements.define('review-card', ReviewCard);
}
