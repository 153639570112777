import CONFIG from './config';

const API_ENDPOINT = {
  HOME: `${CONFIG.BASE_URL}list?api_key=${
    CONFIG.KEY
  }&language=${CONFIG.DEFAULT_LANGUAGE}&page=1`,
  POST: `${CONFIG.BASE_URL}review?api_key=${CONFIG.KEY}`,
  detail: (id) => `${CONFIG.BASE_URL}detail/${id}?api_key=${CONFIG.KEY}`,
};

export default API_ENDPOINT;
