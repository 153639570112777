import API_ENDPOINT from '../globals/apiEndPoint';

class RestaurantDataSource {
  static async allRestaurants() {
    try {
      const response = await fetch(API_ENDPOINT.HOME);
      const responseJson = await response.json();
      return responseJson;
    } catch (err) {
      return {
        error: true,
        message: err.message,
      };
    }
  }

  static async detailRestaurant(id) {
    try {
      const response = await fetch(API_ENDPOINT.detail(id));
      return response.json();
    } catch (err) {
      return {
        error: true,
        message: err.message,
      };
    }
  }
}

export default RestaurantDataSource;
