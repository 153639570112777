import 'typeface-montserrat';
import 'typeface-open-sans';
import './app.css';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import SkipToContentInitiator from './utils/SkipToContentInitiator';
import TopMenuInitiator from './utils/topMenuInitiator';
import UrlParser from './routes/urlParser';
import routes from './routes/routes';
import swRegister from './utils/sw-register';

class App {
  constructor({skipToContentButton, button, topMenu, content, menuLink}) {
    this._skipToContentButton = skipToContentButton;
    this._button = button;
    this._topMenu = topMenu;
    this._content = content;
    this._menuLink = menuLink;

    this._initialAppShell();
  }

  _initialAppShell() {
    SkipToContentInitiator.init({
      button: this._skipToContentButton,
      content: this._content,
    });

    TopMenuInitiator.init({
      button: this._button,
      topMenu: this._topMenu,
      menuLink: this._menuLink,
    });
  }

  async renderPage() {
    const url = UrlParser.parseActiveUrlWithCombiner();
    const page = routes[url];
    this._content.innerHTML = await page.render();
    document.querySelectorAll('.menu-link').forEach((link) => {
      if (link.getAttribute('href').replace('/#', '') === url) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });

    await page.afterRender();
  }
};

const app = new App({
  skipToContentButton: document.querySelector('#skip-to-content'),
  button: document.querySelector('#menu-toggler'),
  topMenu: document.querySelector('#menu-list-block'),
  content: document.querySelector('#primary'),
  menuLink: document.querySelectorAll('.menu-link'),
});

window.addEventListener('hashchange', () => {
  app.renderPage();
});

window.addEventListener('load', () => {
  app.renderPage();
  swRegister();
});
